<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>充值</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="main__container">
      <div class="account">
        余额：
        <span class="account__unit">
          ¥
        </span>
        <span class="account__num">{{ balance }}</span>
      </div>
      <div class="recharge__num">
        充值金额：
        <a-input-number
          class="recharge__num--input"
          :min="0"
          :precision="2"
          v-model="price"
        />
        元
      </div>
      <div class="recharge__container">
        <div
          class="recharge__item"
          v-for="(item, index) in rechargeList"
          :key="index"
          @click="onSelect(index)"
          :class="item.isSelect ? 'selected' : ''"
        >
          {{ item.title }}
        </div>
      </div>
      <a-divider />
      <div class="rule__container">
        <div class="rule__title">充值规则</div>
        <p>
          1.充值免手续费
        </p>
        <p>
          2.充值当日到账，月初、月末为交易高峰期，若到账时间稍有延迟，请耐心等待；若当天未到账，请工作时间9:00-18:00联系客服0571-86067081
        </p>
      </div>
      <div class="operation">
        <a-button class="btn--cancel" @click="onCancel">取消</a-button>
        <a-button class="btn--recharge" type="primary" @click="onRecharge">
          充值
        </a-button>
      </div>
    </div>
  </a-layout>
</template>

<script>
import BreadcrumbItem from "@/components/BreadcrumbItem";
import Breadcrumb from "@/components/Breadcrumb";
import { checkAccountBalance } from "@/services/PayService";

export default {
  name: "Recharge",
  components: { Breadcrumb, BreadcrumbItem },
  data() {
    return {
      rechargeList: [
        {
          title: "充值：1,000元",
          num: "1000.00",
          isSelect: false
        },
        {
          title: "充值：2,000元",
          num: "2000.00",
          isSelect: false
        },
        {
          title: "充值：5,000元",
          num: "5000.00",
          isSelect: false
        },
        {
          title: "充值：9,500元",
          num: "9500.00",
          isSelect: false
        },
        {
          title: "充值：19,500元",
          num: "19500.00",
          isSelect: false
        },
        {
          title: "充值：49,500元",
          num: "49500.00",
          isSelect: false
        }
      ],
      price: null,
      balance: 0
    };
  },
  mounted() {
    this.loadAccountBalance();
  },
  methods: {
    // 选择充值金额
    onSelect(index) {
      this.rechargeList.forEach((item, ind) => {
        if (index === ind) {
          item.isSelect = !item.isSelect;
        } else {
          item.isSelect = false;
        }
      });
      this.$router.push({
        name: "RechargeSecondStep",
        params: { price: this.rechargeList[index].num }
      });
    },
    // 取消
    onCancel() {
      this.$router.go(-1);
    },
    // 充值
    onRecharge() {
      if (this.price <= 0) {
        this.$message.info("请输入充值金额");
        return;
      }
      this.$router.push({
        name: "RechargeSecondStep",
        params: { price: this.price }
      });
    },
    // 查询账户余额
    loadAccountBalance() {
      checkAccountBalance(localStorage.getItem("corporationId")).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          if (data.acctInfo && data.acctInfo.length > 0) {
            this.balance = data.acctInfo[0].balanceAmt;
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.main__container {
  min-height: 88vh;
  margin: 24px;
  background-color: white;
  box-sizing: border-box;
  padding: 20px 79px;
}

.account {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: flex-end;
  font-size: 20px;
  color: black;
  margin: 50px 0 58px 0;
}

.account__unit {
  font-weight: bold;
  font-size: 20px;
}

.account__num {
  font-size: 30px;
  transform: translateY(5px);
  font-weight: 800;
}

.recharge__num {
  display: flex;
  align-items: center;
}

.recharge__num--input {
  width: 200px;
  margin-right: 10px;
}

.recharge__container {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 25px;
  margin-bottom: 60px;
}

.recharge__item {
  width: 110px;
  box-sizing: border-box;
  padding: 15px 0;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(187, 187, 187, 1);
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  cursor: pointer;
}

.selected {
  background-color: rgba(224, 232, 255, 1);
  border: 1px solid rgba(67, 104, 246, 1);
  color: rgba(67, 104, 246, 1);
}

.rule__title {
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
}

.rule__container p {
  color: rgba(102, 102, 102, 0.8);
}

.operation {
  margin: 45px 0 30px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.btn--cancel {
  width: 90px;
  margin-right: 30px;
}

.btn--recharge {
  width: 90px;
}
</style>
